<template>
  <div>
    <CEditor ref="editor" :value="content" :placeholder="`${$t('page.edit')}...`">
      <CButton
        :loading="submitLoading"
        variant="primary"
        size="small"
        @click="save"
      >
        {{ $t('save') }}
      </CButton>
    </CEditor>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { GET_PAGE, GET_PAGES, UPDATE_PAGE } from '@/store/actionTypes';
import { SET_SUCCESS_NOTIFICATION } from '@/store/mutationsTypes';

export default {
  data: () => ({
    content: null,
  }),

  computed: {
    loading() {
      return this.$store.state.pages.singleLoading;
    },
    submitLoading() {
      return this.$store.state.pages.submitLoading;
    },
    page() {
      const routeSlug = this.$route.params.slug;
      return this.$store.state.pages.pages.find((page) => page.slug === routeSlug);
    },
    pageId() {
      if (this.page) return this.page.id;
      return null;
    },
  },

  watch: {
    pageId: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.content = this.page.content;
        }
        if (oldVal && newVal !== oldVal) {
          this.$store.dispatch(GET_PAGE, newVal);
        } else if (!oldVal && newVal) {
          this.$store.dispatch(GET_PAGE, newVal);
        }
      },
    },
  },

  created() {
    this.$store.dispatch(GET_PAGES);
  },

  methods: {
    ...mapMutations([SET_SUCCESS_NOTIFICATION]),
    async save() {
      this.content = this.$refs.editor.getJSON();
      await this.$store.dispatch(UPDATE_PAGE, {
        id: this.page.id,
        content: this.content,
      });
      this.setSuccessNotification({ content: null, show: true });
    },
  },
};
</script>
